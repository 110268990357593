import { getFirestore, getStorage, getCurrentTimestamp } from '../../services/firebase';
import config from '@/config';
import { randomString, validateObj } from './utils';
import {useDocumentHistory} from '../../services/documentHistory'

const DRIVE_FOLDER = config.driveFolderName;

const CATEGORIES = {
  drive: 'drive'
};

const { addDocHistory } = useDocumentHistory()

class Drive {
  constructor() {
    this.firestore = getFirestore();
    this.storage = getStorage(config.storageDriveBucket());
    this.storageRef = this.storage.ref();

    this.fileRef = this.firestore.collection(config.firestore.file);
    this.folderRef = this.firestore.collection(config.firestore.folder);
  }

  createFolder({ name, parentId, user, otherData }) {
    const data = {
      name: '',
      parentId: null,
      user: null,
      createdAt: getCurrentTimestamp(),
      updatedAt: getCurrentTimestamp(),
      ...(otherData || {})
    };

    if (name) {
      data.name = name;
    }
    if (parentId) {
      data.parentId = parentId;
    }
    if (user) {
      data.user = user;
    }

    return this.folderRef.add(data).then(({id})=>{
      addDocHistory({
        id,
        data,
        docCollection: this.folderRef.id
      })
    })
  }

  listFolders({ user, parentId }) {
    const pId = parentId || null;
    let q = this.folderRef;
    if (user) {
      q = q.where('user', '==', user);
    }
    q = q.where('parentId', '==', pId);
    return q.get();
  }

  folderDetail(id) {
    return this.folderRef.doc(id).get();
  }

  deleteFolder(id) {
    return this.folderRef.doc(id).delete();
  }

  updateFolder(id, data) {
    return this.folderRef.doc(id).set({
      ...data,
      updatedAt: getCurrentTimestamp()
    }).then(()=>{
      addDocHistory({
        id,
        data,
        docCollection: this.folderRef.id
      })
    })
  }

  uploadFile({ file, user, metadata }) {
    return new Promise((resolve, reject) => {
      let path;

      // if (user) {
        // path = `/users/${user}/${randomString()}-${file.name}`;
      // } else {
      path = `/${DRIVE_FOLDER}/${randomString()}-${file.name}`;
      // }

      const ref = this.storageRef.child(path);
      ref
        .put(file, {customMetadata: {app: "PeepsPortal", ...(metadata || {})}})
        .then(() => {
          ref
            .getMetadata()
            .then(metadata => {
              resolve({ path, metadata });
            })
            .catch(() => {
              resolve(path, null);
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  createFile({ category, name, folderId, user, path, url, metadata, size, description, tags, otherData }) {
    const data = {
      category: category || CATEGORIES.drive,
      name: '',
      description: '',
      path: '',
      url: '',
      size: 0,
      folderId: null,
      user: null,
      createdAt: getCurrentTimestamp(),
      updatedAt: getCurrentTimestamp(),
      tags: tags || [],
      ...(otherData || {})
    };

    if (name) {
      data.name = name;
    }
    if (folderId) {
      data.folderId = folderId;
    }
    if (user) {
      data.user = user;
    }
    if (path) {
      data.path = path;
    }
    if (url) {
      data.url = url;
    }
    if (metadata) {
      data.metadata = validateObj(metadata);
    }
    if (size) {
      data.size = size;
    }
    if (description) {
      data.description = description;
    }

    const q = this.fileRef.add(data)
    q.then(({id})=>{
      addDocHistory({
        id,
        data,
        docCollection: this.fileRef.id
      })
    })
    return q
  }

  listFiles({ category, user, folderId }) {
    let q = this.fileRef;

    const fId = folderId || null;
    const ctg = category || CATEGORIES.drive;

    q = q.where('category', '==', ctg);
    if (user) {
      q = q.where('user', '==', user);
    }
    q = q.where('folderId', '==', fId);
    return q.get();
  }

  deleteFile(id) {
    return this.fileRef.doc(id).delete();
  }

  fileDetail(id) {
    return this.fileRef.doc(id).get();
  }

  updateFile(id, data) {
    return this.fileRef.doc(id).update({ ...data, updatedAt: getCurrentTimestamp() }).then(()=>{
      addDocHistory({
        id,
        data,
        docCollection: this.fileRef.id
      })
    })
  }

  getDownloadURL(path) {
    return this.storageRef.child(path).getDownloadURL();
  }

  /**
   *
   * @param {string} id
   * @param {object} data
   * @returns {Promise<>}
   */
  updateFileData = (id, data) => {
    return new Promise((resolve, reject) => {
      this.fileDetail(id).then(q => {
        if (!q.exists) {
          reject();
        }
        const newData = { ...q.data(), ...(data || {}) };
        this.updateFile(id, newData)
          .then(() => {
            resolve(newData);
          })
          .catch(error => {
            reject(error);
          });
      });
    });
  };

  moveTo(fileId, folderId) {
    return new Promise((resolve, reject) => {
      this.updateFileData(fileId, { folderId })
        .then(res => {
          resolve(res);
        })
        .catch(error => reject(error));
    });
  }
}

export default Drive;
